@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

.header-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #fff;
}

.app-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

.app-bar {
  background: linear-gradient(to right, #810000, #ff5b50);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  animation: fadeIn 2s ease-in-out;
}

.left-section {
  display: flex;
  align-items: center;
}

.icon-security {
  color: #ffffff;
  animation: rotateIcon 5s linear infinite;
}

.time-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  border-radius: 20px;
}

.status-indicator {
  width: 10px;
  height: 10px;
  background-color: #4caf50;
  border-radius: 50%;
  margin-right: 5px;
  animation: pulse 1.5s infinite;
}

.time-text {
  color: #ffffff;
  font-family: 'Roboto Mono', monospace;
}

.title {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(45deg, #ffffff, #ffffff); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.drawer {
  width: 250px;
  background: linear-gradient(to right, #682525, #ff5b50); /* Lightened the red */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.drawer-list {
  flex-grow: 1;

}

.drawer-list-item {

  padding: 10px 20px;
  border-radius: 4px;
  margin: 10px 0;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
}

.drawer-list-item:hover {
  background: rgba(255, 255, 255, 0.2);
}

.drawer-list-item-icon {
  color: #ffffff;
  margin-right: 10px;
}

.drawer-list-item-text {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    background-color: #ffffff;
  }
  50% {
    transform: scale(1.2);
    background-color: #ffffff;
  }
}

@keyframes textGlow {
  from {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
}
