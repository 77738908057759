@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Roboto:wght@400;500;700&display=swap');
.alert {
  background-color: #005e17; /* Red */
  color: white;
  padding: 10px;
  margin: 10px 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

.header-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #fff;
}

.app-container {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}
